<template>
  <div>
    <div class="alert alert-warning" role="alert">
      кликните кнопку
      <button class="btn btn-sm btn-outline-success shadow-none" disabled>
        ремонт окончен
      </button>
      после этого мы проставим стоимость работ и Вы сможете включить ремонт в
      гарантийный отчет
    </div>
    <div>
      <select
        v-if="USER_INFO.status === 'admin' && repairsList"
        class="form-control form-control-sm shadow-none col-md-3"
        v-model="user"
        @change="filter"
      >
        <option value="all">все</option>
        <option
          v-for="(item, idx) in users_list"
          :key="idx + 'usersLIst'"
          :value="item.id_user"
          >{{ `${item.user} | ${item.count}шт.` }}</option
        >
      </select>
    </div>
    <div class="overflow-auto">
      <table class="table table-sm" v-if="repairsList && repairsList.length">
        <tr>
          <th></th>
          <th>№</th>
          <th>№ СЦ</th>
          <th v-if="USER_INFO.status === 'admin'">СЦ</th>
          <th>наименование</th>
          <th>дата принятия в ремонт</th>
          <th v-if="USER_INFO.status === 'admin'">подтвержден</th>
          <th>s№</th>
          <th></th>
        </tr>
        <tr v-for="(item, idx) in repairsList" :key="idx">
          <td>{{ idx + 1 }}</td>
          <td @click="goRepair(item.remont_id)" class="cursor text-nowrap">
            {{ item.repair_number }}
          </td>
          <td @click="goRepair(item.remont_id)" class="cursor text-nowrap">
            {{ item.receiptNumber }}
          </td>
          <td
            v-if="USER_INFO.status === 'admin'"
            @click="goRepair(item.remont_id)"
            class="cursor"
          >
            {{ item.user }}
          </td>
          <td @click="goRepair(item.remont_id)" class="cursor">
            {{ item.name_instr }}
          </td>
          <td @click="goRepair(item.remont_id)" class="cursor">
            {{ item.dateRepair }}
          </td>
          <td
            @click="goRepair(item.remont_id)"
            class="cursor"
            v-if="USER_INFO.status === 'admin'"
          >
            {{ item.repair_confirm_data2 }}
          </td>
          <td @click="goRepair(item.remont_id)" class="cursor">
            {{ item.serialNumberGroup + " " + item.serialNumber }}
          </td>
          <td class="text-right">
            <button
              class="btn btn-sm btn-outline-success shadow-none text-nowrap"
              @click="
                issued(item.remont_id, item.repair_number, item.name_instr)
              "
            >
              ремонт окончен
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { HTTPGET } from "@/api";
export default {
  name: "RepairsPendingIssueList",
  data: () => ({
    repairsList: null,
    users_list: []
  }),
  computed: {
    ...mapGetters([
      "USER_INFO",
      "REPAIRS_LIST_SCROLL",
      "SELECTREPAIRSPENDINGISSUELISTUSER"
    ]),
    user: {
      get() {
        return this.SELECTREPAIRSPENDINGISSUELISTUSER;
      },
      set(value) {
        this.set_user_repairs_pending_issue_list(value);
      }
    }
  },
  mounted() {
    this.ADDPATH("список ремонтов ожидающих выдачи");
    this.getData(this.SELECTREPAIRSPENDINGISSUELISTUSER);
  },
  methods: {
    ...mapMutations([
      "set_repairs_list_scroll",
      "set_user_repairs_pending_issue_list"
    ]),
    ...mapActions(["ADDPATH", "GET_REPAIRS_PENDING_ISSUE_COUNT"]),
    getData(user) {
      HTTPGET({
        url: "repairs/repairs_pending_issue_list.php",
        params: {
          user
        }
      })
        .then(response => {
          this.repairsList = response.data.repairs;
          this.users_list = response.data.users;
        })
        .then(() => window.scrollBy(0, this.REPAIRS_LIST_SCROLL))
        .then(() => this.set_repairs_list_scroll(0));
    },
    issued(id, repair_number, name_instr) {
      if (confirm(`${repair_number} ${name_instr} - ремонт окончен`)) {
        HTTPGET({
          url: "repairs/repairs_issued.php",
          params: {
            id
          }
        })
          .then(() => this.getData(this.SELECTREPAIRSPENDINGISSUELISTUSER))
          .then(() => this.GET_REPAIRS_PENDING_ISSUE_COUNT());
      }
    },
    goRepair(id) {
      this.$router.push(`/repairs/repair/${id}`);
      this.set_repairs_list_scroll(window.pageYOffset);
    },
    filter() {
      this.getData(this.SELECTREPAIRSPENDINGISSUELISTUSER);
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.7em;
  margin-top: 10px;
}
td,
th {
  vertical-align: middle;
}
.cursor {
  cursor: pointer;
}
.alert {
  margin-top: 10px;
}
</style>
